/**
 * shows icon for dark mode toggler
 * @param {*} theme 'light' or 'dark'
 */
 let showDarkModeThemeIcon = theme => {
    // record status to help when toggling
    document.getElementById('toggle-dark-mode').setAttribute('data-theme', theme)

    let iconSelector = document.getElementById('dark-mode-icon');

    iconSelector
        .classList.remove('bi-brightness-high', 'bi-moon-stars', 'bi-circle-half');

    switch(theme) {
        case 'auto':
            iconSelector.classList.add('bi-circle-half');
            iconSelector.title = 'Auto';
            break;
        case 'dark':
            iconSelector.classList.add('bi-moon-stars');
            iconSelector.title = 'Dark';
            break;
        default: // light
            iconSelector.classList.add('bi-brightness-high');
            iconSelector.title = 'Light';
    }
}

/**
 * get OS theme
 * @returns 'light' or 'dark'
 */
let getSystemTheme = () => {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
}

/**
 * get preferred dark mode theme: either previously stored or system's
 * @returns string 'light' or 'dark'
 */
let getPreferredTheme = () => {
    let theme = localStorage.getItem('theme'); // previously stored
    let status = theme;

    if (!theme) { // use the system's
        theme = getSystemTheme();
        status = 'auto';			
    }

    showDarkModeThemeIcon(status); // either sun or moon icon
    
    return theme;
}

/**
 * store dark mode theme in local storage
 * @param {*} theme either 'light' or 'dark'
 */
let storePreferredTheme = theme => {
    localStorage.setItem('theme', theme);
}

let deletePreferredTheme = () => {
    localStorage.removeItem('theme');
}

/**
 * shows page in preferred theme
 * @param {*} theme either 'light' or 'dark'
 */
let setPreferredTheme = theme => {
    document.querySelector('html').setAttribute('data-bs-theme', theme);
}

let preferredTheme = getPreferredTheme();

// storePreferredTheme(preferredTheme); // only when clicked
setPreferredTheme(preferredTheme);

// // Toggle Dark Mode
document.getElementById('toggle-dark-mode').addEventListener('click', function(e) {
    let $this = this;
    let currentMode = $this.getAttribute('data-theme');

    let theme;

    if (currentMode == 'auto') { // change to 'dark'
        theme = 'dark';
        setPreferredTheme(theme);
        storePreferredTheme(theme);
    } else if (currentMode == 'dark') { // change to 'light'
        theme = 'light';
        setPreferredTheme(theme);
        storePreferredTheme(theme);
    } else { // light; change to 'auto'
        theme = 'auto';
        setPreferredTheme(getSystemTheme());
        deletePreferredTheme();
    }

    showDarkModeThemeIcon(theme);
});

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
    let theme = localStorage.getItem('theme'); // previously stored

    // Change only if dark mode is auto (i.e., not previously set)
    if (!theme) { // use the system's
        theme = getSystemTheme();
        setPreferredTheme(theme);
    }
});

