$(() => {
    let navBar = $('#main-navbar');
    let header = document.getElementById('header');
  
    if (header != null) { // only on home page
        $(window).on('scroll', (e) => {
            if (window.scrollY > header.offsetHeight - 56) {
                navBar.addClass('bg-dark-trans');
            } else {
                if (!$('#navbarNav').hasClass('show')) { // closed
                    navBar.removeClass('bg-dark-trans');
                }
            }       
        });

        $('.navbar-toggler').on('click', function(e) {
            let $this = $(this);
    
            if ($this.hasClass('collapsed')) { // closed
                if (window.scrollY < header.offsetHeight - 56) {
                    setTimeout(function(e) {
                        navBar.removeClass('bg-dark-trans');
                    }, 300);
                }
            } else { // open
                navBar.addClass('bg-dark-trans');
            }
        });
    } else {
        navBar.addClass('bg-dark-trans');
    }

    // Status Toast
    let statusToast = $('#status-toast');
    if (typeof statusToast != 'undefined') {
        statusToast.toast('show');
    }
});